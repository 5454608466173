<template>
  <v-card class="mt-3">
    <v-card-title>
      <h5>Clientes</h5>
    </v-card-title>
    <v-card-text class="pa-2">
      <highcharts :options="chartOptions" :deepCopyOnUpdate="false" />
    </v-card-text>
  </v-card>
</template>

<script>
  import Highcharts from "highcharts";
  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  HighchartsNoData(Highcharts);

  export default {
    name: "ChartClientesTecnicos",
    props: {
      competencias: {
        type: Array,
        required: true,
      },
      dadosAlimentadores: {
        type: Object,
        required: true,
      },
      dadosSubestacoes: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      chartOptions: {},
    }),
    created() {
      this.setChartOptions();
    },
    methods: {
      setChartOptions() {
        this.chartOptions = {
          chart: {
            type: "spline",
            zoomType: "x",
            height: 300,
          },
          credits: { enabled: false },
          title: { text: null },
          subtitle: { text: null },
          xAxis: {
            categories: this.competencias,
            crosshair: true,
            title: { text: "Competência" },
          },
          yAxis: {
            title: { text: null },
          },
          plotOptions: {
            series: {
              marker: {
                enabledThreshold: 16,
                radius: 3,
              },
              animation: false,
            },
          },
          lang: {
            noData: "Sem dados para exibição",
          },
          series: [
            this.getChartSerie(this.dadosAlimentadores, "Alimentadores"),
            this.getChartSerie(this.dadosSubestacoes, "Subestações"),
          ],
        };
      },
      getChartSerie(dados, serieName) {
        return {
          name: serieName,
          animation: false,
          data: this.competencias
            .map((category) => {
              return category in dados
                ? {
                    x: this.competencias.indexOf(category),
                    y: Number(dados[category].clientes),
                  }
                : { x: this.competencias.indexOf(category), y: null };
            })
            .filter((val) => val !== null && val.y !== null && val.y > 0),
        };
      },
    },
    watch: {
      competencias() {
        this.setChartOptions();
      },
    },
  };
</script>

<style></style>
